.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-rodada {
  outline: none !important;
  outline-offset: none !important;
  font-size: 28px !important; 
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important; 
  color: #1d1717!important; 
  margin: 1% !important; 
  box-shadow: 5px 5px 3px rgba(46, 46, 46, 0.62)!important; 
  width: 60px!important; 
}

.btn-rodada:hover {
  outline: none !important;
  outline-offset: none !important;
  /* font-size: 38px !important;  */
  background-color: aqua!important; 
}

.btn-registrar {
  font-size: 38px !important; 
  color: #fcfbfd !important;
  background-color:  rgb(75, 72, 77) !important;
  margin-bottom: 20px !important;
  box-shadow: 5px 5px 3px rgba(46, 46, 46, 0.62) !important;
}